<script setup lang='ts'>
import { LOAD_STATUS } from '~~/../common/src'
import { LogicSwiper } from '~~/src/logic/swiper'
import { BannerShowLocation } from '~~/src/server/modules/article/type'
import type { ArticleBannerParams } from '~~/src/server/modules/article/type'
const props = withDefaults(defineProps<{
  params: ArticleBannerParams
}>(), {
  params: () => ({
    bannerShowLocation: BannerShowLocation.HOME,
    pageNum: 1,
    pageSize: 8,
  }),
})
const { swiperList, serverInitHandler: swiperServerInitHandler, state, clientHandler } = LogicSwiper()

onMounted(async () => {
  if (state.value === LOAD_STATUS.ERROR)
    clientHandler(props.params)
})
try {
  await swiperServerInitHandler(props.params)
}
catch (error) {
  recordError(error)
}
</script>

<template>
  <layout-swiper :list="swiperList.format" v-bind="$attrs" />
</template>
